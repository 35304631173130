@font-face {
  font-family: 'Sign Painter House Script';
  font-style: normal;
  font-weight: 400;
  src: local('Sign Painter House Script'),
    url('font-files/SignPainter-HouseScript.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans';
  font-style: '16px';
  line-height: '20px';
  font-weight: 400;
  src: local('DM Sans Regular'), url('font-files/DMSans-Regular.ttf') format('truetype');
}
