/*======== Header Scss ========*/
.app-header {
  .rct-header {
    background-color: $navbar-bg;
    z-index: -9 !important;
    box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2) !important;
    .site-logo{
      padding:1rem 4rem 0.8rem 2rem;
      background-color: $white;
    }
    .navbar-left{
      margin-left:0.85rem;
    }
    .cart-icon{
      .dropdown-menu{
        .dropdown-foot{
          a{
            color:$white !important;
          }
        }
      }
    }
  }
}
header {
  .dropdown-group-link {
    // @include border (1px solid, $gray-200, top bottom left right);
    border-radius: $border-radius;
    font-size: 0.875rem; //14px;
    text-transform: capitalize;
    color: $gray-900;
    // background-color: $gray-200;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.12);
    ~ .dropdown-menu {
        width: 13rem;
    }
    i {
      margin-right: 5px;
      vertical-align: middle;
    }
    .dropdown-toggle::after {
      margin-left: 0.625rem;
      vertical-align: middle;
    }
  }
}
.header-icon {
  height: $navbar-icon-height;
  width: $navbar-icon-width;
  @include border(1px solid, $input-border-color, top bottom left right);
  border-radius: $border-radius;
  line-height: $navbar-icon-height;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.25rem; //20px;
  transition: all 0.5s ease 0s;
  color: $gray-600;
  &:hover, &:focus, &.active {
    border-color: $primary !important;
    color: $primary !important;
    a {
      color: $primary;
    }
  }
}
.rct-header{
  .quicklink-wrapper{
    .header-icon{
      i{
        font-size:1.5rem;
        line-height:inherit;
      }
      @include border(1px solid, $input-border-color, top bottom left right);
    }
    .dropdown-menu{
      width: 20rem !important;
      left:0 !important;
      right:auto !important;
      .quicklink-content{
        .quicklink-top{
          padding:1rem 1.25rem;
        }
        .quicklink-list{
          background-color:$quicklink-bg;
          li{
            display:inline-block;
            width:46.5%;
            box-shadow:$quicklink-box-shadow;
            margin:0.3125rem;
            a{
              padding:1.25rem;
              background-color:$white;
              display:flex;
              align-items: center;
              i{
                width:20%;
              }
              span{
                font-size:$font-size-sm;
                line-height:$font-size-sm;
                transition:color 0.3s ease;
              }
              &:hover{
                background-color: $white !important;
                span{
                  color:$primary !important;
                }
              }
            }
          }
        }
      }
      &:after,&:before{
        right:auto !important;
        left:3%;
        border-bottom-color:$primary;
      }
    }
  }
}

.humburger {
  color: $hamburger-icon-color !important;
  border: 1px solid $input-border-color !important;
}
.navbar-right {
  margin: 0;
  >li:not(.dropdown), >li:not(.upgrade-btn.button) {
    button {
      color: $gray-600;
      font-size: 1.375rem; //22px;
      &:hover,
      &:focus,
      &.active {
        color: $primary;
      }
    }
  }
}
.notification-icon {
  padding: 0;
  margin: 0;
  .badge-bottom {
    position: absolute;
    bottom: 0.7rem;
    right: 0;
    left: 3rem;
  }
  &:hover {
    a {
      button {
        color: $blue;
      }
    }
  }
  a {
    button {
      color: $gray-600;
      font-size: 1.375rem; //22px;
    }
  }
  &.show .dropdown-menu {
    opacity: 1;
    transform: translate3d(0, 54px, 0)!important;
    visibility: visible;
  }
  .dropdown-menu {
    @extend .dropdown-menu;
    width: 350px;
    right: -22px!important;
    .dropdown-head {
      padding: 0.625rem 0.9375rem; //10px 15px;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      @include border(1px solid, $input-border-color, bottom);
      span {
        font-weight: 600;
        color: $gray-900;
        font-size: 0.875rem;
      }
    }
    .dropdown-foot {
      padding: 0.625rem 0.9375rem; //10px 15px;
      font-weight: 600;
      box-shadow: 0px -2px 2px rgba(0,0,0,0.1);
    }
    .dropdown-body {
      li {
        position: relative;
        padding: 0.625rem; //10px;
        @include border(1px solid, $input-border-color, bottom);
        button {
          min-height: 25px !important;
          padding: 2px 10px !important;
          font-size: 12px;
          min-width: 70px !important;
          i {
            margin-right: 0.5rem;
          }
        }
      }
      .media {
        .media-body {
          h5 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.hover-close {
  opacity: 0;
}
.cart-icon {
  @extend .notification-icon;
  li{
    &:hover {
      .hover-close {
        opacity: 1;
      }
    }
  }
}
@media (max-width: 1199px) and (min-width: 320px) {
  .rct-header {
    &.mui-fixed {
      left: 0;
    }
  }
  .rct-app-content {
    width: 100% !important;
  }
}
/*========= Dashboard Overlay ======*/
@-webkit-keyframes show {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes show {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
.show {
  -webkit-animation-name: show;
  animation-name: show;
}
.dashboard-overlay {
	width: 100%;
	background-color: rgba(0,0,0,0.9);
	position: absolute;
	left: 0;
	right: 0;
	padding: 1.875rem; //30px;
	height: 100vh;
  top: 63px;
  z-index: 99;
  overflow-y: auto;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  .dashboard-overlay-content {
    color: $gray-900;
    .arrow-icon {
      font-size: 2.25rem; //36px;
      color: $success;
    }
    .totle-status {
      h2 {
        font-size: 1.5rem; //24px;
      }
    }
  }
  .table {
    tr {
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .progress {
    height: 6px;
  }
  .rating-star {
    li {
      font-size: 1.875rem; //30px;
    }
  }
  .report-title {
  	background: $gray-100;
  	font-size: 0.875rem; //14px;
  	padding: 0.9375rem; //15px;
    margin-bottom: 0;
  }
  .overlay-head {
    padding: 0 0.9375rem; //15px;
    .closed {
      font-size: 1.375rem; //22px;
      color: $white;
      font-weight: 700;
    }
  }
}
/*======== Search Form =======*/
.search-icon {
  .search-wrapper{
    position: relative;
    &::before {
      content: "\f1c3";
      font-family: 'Material-Design-Iconic-Font';
      color: $input-placeholder-color;
      position: absolute;
      left:0;
      top:0;
      bottom:0;
      display:flex;
      align-items:center;
      padding:0 1.25rem;
      font-size: 1.25rem;
      cursor:pointer
    }
    .search-input-lg{
       padding:0.5rem 0.75rem 0.5rem 3.25rem;
       width:200px;
       transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
       font-size: 0.875rem;
      &:focus{
        box-shadow:none;
        width:250px
      }
    }
  }
  .search-form-wrap{
    transform:translateY(-100%);
    transition:all 0.5s ease-in-out;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(0,0,0,0.9);
    z-index:999;
    opacity:0;
    .close-btn{
      position:absolute;
      right:2.5rem;
      top:4rem;
    }
    form{
      width:75%;
      .form-group{
        position: relative;
        .search-input{
          border:none;
          border-bottom: 1px solid $white;
          background-color:transparent !important;
          box-shadow:none !important;
          padding:1rem 3rem 1rem 0;
          color:$white;
        }
        .search-btn{
          position:absolute;
          right:0;
          top:0;
          bottom:0;
        }
      }
    }
    &.search-slide{
      transform:translateY(0);
      opacity:1;
    }
  }
}

/*======= Responsive ========*/

@media (max-width: 500px) {
  .rct-header .site-logo{
    display:none !important;
  }
}
@media (min-width: 725px) {
  .search-form-wrap,.search-icon-btn{
    display:none !important;
  }
  .search-wrapper{
    display: block !important;
  }
}
@media (max-width: 724px) {
  .app-horizontal{
    .app-header{
      .site-logo{
        padding: 0.875rem 1rem !important;
      }
      .navbar-right{
        margin: 0 0 0 -1.25rem;
      }
    }
  }
  .search-form-wrap,.search-icon-btn{
    display:block !important;
  }
  .search-wrapper,.search-icon:before{
    display: none !important;
  }
}
@media (max-width: 991px) {
  .app-main-container {
    > div {
      > div {
        box-shadow: none !important;
      }
    }
  }
  .notification-icon, .cart-icon {
    .dropdown-menu {
      .text-muted.fs-12 {
      	display: none;
      }
    }
  }
}
@media (max-width:450px) and (min-width:320px) {
  .notification-icon, .cart-icon, .rct-dropdown {
    .dropdown-menu {
      &::after, &::before {
        border: none !important;
      }
    }
  }
  .notification-icon, .cart-icon {
    .dropdown-menu {
      width: 280px;
      right: 0px !important;
    }
  }
}
@media screen and (max-width:359px) {
  .notification-icon, .cart-icon {
    .dropdown-menu {
      width: 240px;
    }
  }
}
