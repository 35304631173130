@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Space+Mono:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Raleway:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Alegreya:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Cormorant:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Proza+Libre:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Space+Grotesk:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Lora:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Archivo+Narrow:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Eczar:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Balthazar&display=swap');

/*======= All Common Style Mention Here ======*/
html {
  font-size: $html-font-size;
}
@media (max-width: 1700px) {
  html {
    font-size: ($html-font-size - 1);
  }
}
@media (max-width: 1366px) {
  html {
    font-size: ($html-font-size - 1);
  }
}
@media (max-width: 1280px) {
  html {
    font-size: ($html-font-size - 1);
  }
}
@media (max-width: 991px) {
  html {
    font-size: ($html-font-size - 2);
  }
}
body,
html {
  height: 100%;
}
html {
  font-family: $font-family-base;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
  color: $body-color;
  background-color: #f2f5f8;
  position: relative;
  overflow: hidden;
  width: 100%;
}
[tabindex='-1']:focus {
  outline: none;
}
ul,
ol {
  padding: 0;
}
select,
button,
textarea,
input {
  vertical-align: baseline;
}
html,
body {
  &[dir='rtl'],
  &[dir='ltr'] {
    unicode-bidi: embed;
  }
}
bdo[dir='rtl'] {
  direction: rtl;
  unicode-bidi: bidi-override;
}
bdo[dir='ltr'] {
  direction: ltr;
  unicode-bidi: bidi-override;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-normal;
}
p {
  font-size: 14px;
}
a {
  transition: all 0.5s ease 0s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus,
  &.active {
    color: inherit;
    text-decoration: none;
    outline: 0;
  }
}
*::-moz-selection {
  background-color: $primary;
  color: $light;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $input-placeholder-color !important;
}
* {
  outline: none;
  &:hover,
  &:focus,
  &.active {
    outline: none;
  }
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
sup {
  top: -0.9em;
}
disabled {
  cursor: none;
}
.vr-super {
  vertical-align: super;
}
.pos-static {
  position: static !important;
}
.overlay-wrap {
  position: relative;
}
.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.overflow-hidden {
  overflow: hidden;
}
.border-rad-sm {
  border-radius: 5px;
}
address {
  span {
    display: block;
  }
}
.pos-center {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
.center-holder {
  display: inline-block;
  height: auto;
  padding: 1.25rem;
  vertical-align: middle;
  width: 90%;
}
.strike p {
  text-decoration: line-through;
}
.row.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.switch {
  &.on {
    background-color: $success;
  }
}
.modal-open {
  overflow: visible !important;
}
[class*='col-'] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
}
/*======== Breadcrumb =========*/
.breadcrumb {
  background: none;
}
/*======== Loader Scss ========*/
.rct-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  .spring-spinner-rotator {
    border-top-color: $blue !important;
    border-right-color: $blue !important;
  }
}
.loader-overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 99;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
}
.preloader-sidebar {
  background-color: rgba(0, 0, 0, 0.2);
}
.preload-widget {
  background-color: $white;
  box-shadow: $block-shadow;
}
.preload-header {
  background-color: $white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.fixed-plugin {
  .agency-bg {
    display: none;
  }
}
/*======== Responsive =========*/
.content-wrapper {
  margin-bottom: 10px;
  min-height: calc(100vh - 250px);
}
@media (max-width: 1199px) and (min-width: 768px) {
  .d-sm-full {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .d-sm-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
}
@media (max-width: 991px) and (min-width: 576px) {
  .d-xs-full {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .d-xs-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
}
@media (max-width: 575px) and (min-width: 450px) {
  .d-xxs-full {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .d-xxs-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
}
@media (max-width: 450px) {
  .d-xs-none {
    display: none;
  }
}
@media (max-width: 575px) and (min-width: 320px) {
  .display-n {
    display: none !important;
  }
}
