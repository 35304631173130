/*======== Agency Menu Layout ========*/

.app-agency{
   .app-container{
      margin:0 auto;
      .app-header{
         padding:1rem 0;
         margin:0 1rem;
         .rct-header{
            background-color: transparent !important;
            box-shadow:none !important;
            >div{
               padding:0 !important;
            }
            .site-logo{
               background-color: transparent !important;
               padding:0 !important;
            }
            .navbar-right{
               li{
                  &.summary-icon{
                     display: none;
                  }
                  a,button,a i,a button{
                     color:$white;
                  }
                  &.rct-dropdown{
                     >a{
                        background-color: $primary;
                     }
                     div >ul >li a{
                        color: $gray-900;
                        &:hover{
                           color: $white;
                        }
                     }
                  }
               }
            }
         }
      }
      .rct-page{
         background-color:$gray-200;
         border-radius:10px;
         overflow:hidden;
         margin:0 1rem;
         .agency-menu{
            height:4.688rem;
            padding-right: 1.5rem;
            .nav{
               .nav-item{
                  &:hover{
                     >.nav-link{
                        color:$primary;
                     }
                  }
               }
               >.nav-item{
                  >.nav-link{
                     height:4.688rem;
                  }
                  &.nav-item-active,.sub-menu,.sub-menu .nav-item >.sub-menu-child,.sub-menu-child .nav-item >.sub-menu-sub-child{
                     &:after{
                        content: "";
                        position: absolute;
                        border: 9px solid rgba(0,0,0,1);
                        border-color: transparent transparent $white $white;
                        box-shadow: -3px 3px 7px 0 rgba(204, 204, 204, 0.5);
                        z-index:999;
                        transition:all 0.2s ease;
                     }
                  }
                  .sub-menu,.sub-menu-child,.sub-menu-sub-child{
                     box-shadow: 0 0 15px 1px rgba(69,65,78,.2);
                     border-radius:5px;
                     transform: translateY(2rem);
                     transition: transform 0.2s ease-in;
                  }
                  .sub-menu{
                     top:4.75rem;
                     left:1.5rem;
                     >.nav-item{
                        position:relative;
                        &:after{
                           content:"";
                           left:0;
                           top:0;
                           bottom:0;
                           @include border(5px solid, $primary, left);
                           position:absolute;
                           opacity:0;
                           transition: all 0.3s ease-in-out;
                        }
                        &:hover{
                           &:after{
                              opacity:1;
                           }
                        }
                     }
                     li{
                        &:first-child{
                           .nav-link{
                              border-radius:5px 5px 0 0;
                           }
                        }
                        &:last-child{
                           .nav-link{
                              border-radius:0 0 5px 5px;
                           }
                        }
                        .deep-level{
                           width:500px !important;
                        }
                     }
                     &:after{
                        top: -0.5rem;
                        left: 2rem;
                        transform: rotate(135deg);
                     }
                     .nav-item{
                        .nav-link{
                           display:flex;
                           align-items: center;
                        }
                        >.sub-menu-child{
                           left:15.8rem;
                           width: 75%;
                           &:after{
                              top: 1rem;
                              left: -0.5rem;
                              transform: rotate(45deg);
                           }
                           li{
                              .sub-menu-sub-child {
                                 width:75%;
                                 left:12rem;
                                 li{
                                    &:hover{
                                       >.nav-link{
                                          color:$primary;
                                       }
                                    }
                                 }
                                 &:after{
                                    top: 1rem;
                                    left: -0.5rem;
                                    transform: rotate(45deg);
                                 }
                              }
                              &:hover{
                                 >.sub-menu-sub-child{
                                    transform: translateY(0rem);
                                 }
                              }
                           }
                        }
                        &:hover{
                           >.sub-menu-child{
                              transform: translateY(0rem);
                           }
                        }
                     }
                  }
                  &:hover{
                     .sub-menu{
                        transform: translateY(0);
                     }
                  }
                  &.nav-item-active{
                     &:hover{
                        &:after{
                           content:none;
                        }
                     }
                     >.nav-link{
                        color:$primary;
                     }
                     &:after{
                        top: 4rem;
                        left: 3.55rem;
                        transform: rotate(-45deg);
                     }
                  }
               }
            }
         }
      }
      .rct-footer{
         background-color: transparent !important;
         padding-left:0;
         padding-right:0;
         margin:0 1rem;
         *{
            color:$white;
         }
      }
      .fixed-plugin{
         .agency-bg{
            display: block !important;
            li.active{
               .badge{
                  border:3px solid $primary;
                  box-shadow: 0 0 5px $blue;
               }
            }
         }
      }
   }
}
@media (min-width: 1400px){
   .app-agency {
      .app-container{
         width:1340px
      }
      .rct-footer,.app-header,.rct-page{
         margin:0 1.875rem;
      }
   }
}

@media (min-width: 991px){
   .app-agency {
      .agency-menu{
         .hamburger-icon{
            display:none;
         }
      }
      .fixed-plugin{
         .sidebar-overlay,
         .sidebar-color,
         .sidebar-img,
         .sidebar-img-check,
         .background-img,
         .mini-sidebar-option,
         .box-layout-option{
            display:none;
         }
      }
   }
}
@media (max-width: 990px){
   .app-agency {
      .app-header{
         padding:0 !important;
      }
      .agency-menu{
         padding:0 1.5rem;
         height:3.5rem !important;
         .nav{
            display:none;
         }
         .hamburger-icon{
            display:block;
         }
      }
      .rct-page{
         >.rct-scroll{
            height: calc(100vh - 9.75rem) !important;
         }
      }
      .rct-footer{
         padding:0.5rem 0;
         justify-content: center !important;
         ul{
            display:none !important;
         }
      }
   }
}

@media (max-width: 500px){
   .app-agency{
      .rct-header .site-logo {
         display: block !important;
         .logo-normal{
            display: none !important;
         }
      }
   }
}

@media (max-width: 1050px){
   .app-agency{
      .horizontal-menu{
         >ul >li >a{
            padding:0.5rem 1rem !important;
         }
      }
   }
}

@media (max-width: 1200px) and (min-width:900px){
   .agency-menu{
      .nav{
         >.nav-item:last-child{
            >.sub-menu{
               >.nav-item:last-child{
                  &:hover{
                     >.nav-link{
                        &:after{
                           transform:rotate(90deg);
                        }
                     }
                  }
                  .sub-menu-child{
                     left:0 !important;
                     top:3rem !important;
                     width:100% !important;
                     &:after{
                        content:none !important;
                     }
                     li{
                        .sub-menu-sub-child{
                           left:15.8rem !important;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
