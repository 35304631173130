.touch-scene-editor {
  &.wrapper {
    width: 100%;
  }

  .save-group {
    margin-top: 16px;
    width: 100%;

    > button {
      flex-grow: 8;

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .preview-block {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 8px;

    a.a-preview {
      &, &:visited {
        color: #5D92F4;
      }
    }

    > button {
      cursor: pointer;
      border: 0;
      background: transparent;
      padding: 5px;
      margin-left: 4px;
    }
  }

  .control-field {
    margin: 0 0 16px;
    padding: 0 0 16px;
    //border-bottom: 1px solid #efefef61;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .control-field.library {
    label {
      white-space: nowrap;
    }
  }

  .controls {
    width: 20%;

    display: flex;
    align-items: stretch;

    & > div {
      width: 100%;
      padding: 32px 32px 32px 16px;

      max-height: 77vh;
      overflow: auto;

    }

    .routes-list {

      & > div {
        margin: 0 0 16px 0;
      }

      label {
        font-size: 14px;
        line-height: 18px;
      }

      label.label {
        color: #666;
      }

      div.input {
        margin-right: 8px;
      }

      div.field {
        display: flex;
        align-items: end;

        button {
          height: 24px;
          padding: 0;
          background: transparent;
          border: none;
        }

        button.delete {
          margin-bottom: 8px;
        }

        .move-buttons {
          margin-bottom: 4px;
        }

        .move-buttons {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .up, .down {
            width: 24px;
            height: 16px;
            display: flex;
            align-items: start;
          }

          .down {
            align-items: end;
          }
        }

      }
    }
  }

  .scene {
    text-align: center;
  }
}

.save-as-dialog {
  .buttons {
    margin-top: 16px;
    text-align: right;
  }

  .text-warning {
    font-size: 1.1rem;
  }
  .form-control.overwrite {
    border-color: red;
  }
}
