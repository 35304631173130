.image-upload-form {
  .buttons {
    button {
      margin-right: 12px;
    }
  }

  .dropzone {
    width: 100%;
    height: 100%;
    font-size: 14px;
    text-align: center;
    padding: 20px 10px;
    transition: background-color 0.5s;
    border-radius: 5px;
    border: 1px dashed #C7C7C7;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 14px;
  }

}

@keyframes sqloader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.sqloader {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFFA6;

  div {
    display: block;
    margin: 0 8px;
    width: 16px;
    background: #FFFFFF99;
    animation: sqloader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    &:nth-child(1) {
      animation-delay: -0.24s;
    }
    &:nth-child(2) {
      animation-delay: -0.12s;
    }
    &:nth-child(3) {
      animation-delay: 0s;
    }
  }
}

